import {
  Col, Row, Typography, Toggle, Button, IconButton, Container, Inline,
} from '@airbus/components-react';
import { Bookmark, ArrowBack } from '@airbus/icons/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Crown from '../../Shared/Crown/Crown';
import GenericTable from '../../Shared/GenericTable/GenericTable';
import { useAppDispatch, useAppSelector } from '../../../store/hooksTypes';
import { RootState } from '../../../store/store';
import { createRepoDeltaTableView, createViewGeneralInfoData, createRepoDeltaTableViewFilter } from '../../../models/mpdRepositoryModel/mpdRepositoryAsyncThunk';
import {
  clearAllRepoDeltaTableView, setFilterPanelOpen, clearGeneralInfoCardData, clearAllRepoDeltaTableViewData,
} from '../../../models/mpdRepositoryModel/mpdRepositorySlice';
import { getTableColumns, generateHidableColumns } from '../../MpdTaskTable/mpdTableStructureUtils';
import { addBanner, clearBannerLocation } from '../../../models/bannerModel/bannerSlice';
import { bannerLocationMap, DELTA_DATA_LOAD_ERROR } from '../../../models/bannerModel/constants';
import MpdRepositoryEmtlDeltaGeneralInfoCard from '../../MpdRepositoryEmtlDeltaGeneralInfoCard/MpdRepositoryEmtlDeltaGeneralInfoCard';
import {
  TABLE_TITLE, NO_SEARCH_COLUMNS, TableColumnState, generateTableColumns,
} from './EmtlDeltaViewTable/emtlDeltaViewTableUtils';
import './EmtlDeltaViewContainer.scss';

const EmtlDeltaViewContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pageNo, setpageNo] = useState(0);
  const [limit, setLimit] = useState(10);
  const [columns, setColumns] = useState<Array<repositoryDeltaViewDataHeaders>>([]);

  const [searchPair, setSearchPair] = useState({ columnName: '', columnInput: '' });
  const [searchOptions, setSearchOptions] = useState<Array<{ [key: string]: string }>>([]);
  const [filterGroup, setFilterGroup] = useState<Array<{ columnName: string, columnInput: string }>>([]);

  const {
    RepositoryDeltaGeneralInfoData: generalInfoCardData,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  const {
    RepositoryDeltaViewData: viewData,
    RepositoryDeltaViewFilterData: viewFilterData,
    RepositoryDeltaViewFilterDataSanitized: viewFilterDataSanitized,
    RepositoryDeltaViewDataLoading: viewDataLoading,
    RepositoryDeltaViewDataError: viewDataError,
  } = useAppSelector((state: RootState) => state.repositoryTaskList);
  useEffect(() => {
    dispatch(createViewGeneralInfoData(id)());
    return () => {
      dispatch(clearAllRepoDeltaTableView());
      dispatch(clearGeneralInfoCardData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(clearAllRepoDeltaTableViewData());
    dispatch(createRepoDeltaTableView(
      id,
      (pageNo + 1),
      limit,
      filterGroup.map((filter) => ({ filter_column: filter.columnName, filter_value: filter.columnInput })),
    )());
  }, [filterGroup, id, limit, pageNo, dispatch]);

  useEffect(() => {
    if (viewDataError) {
      dispatch(clearBannerLocation(bannerLocationMap.GLOBAL));
      dispatch(clearBannerLocation(bannerLocationMap.REPOSITORY_MODULE));
      dispatch(addBanner(DELTA_DATA_LOAD_ERROR));
    }
  }, [viewDataError, dispatch]);

  useEffect(() => {
    setColumns(viewData.headers.slice());
  }, [viewData.headers]);

  useEffect(() => {
    const filterOptions: Array<{ [key: string]: string }> = [];
    viewFilterDataSanitized.column_value.forEach((option) => (
      filterOptions.push({ [viewFilterDataSanitized.column_name]: option })
    ));
    setSearchOptions(filterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewFilterData]);

  const onPaginationChange: (data: {
    pageIndex: number,
    pageSize: number
  }) => void = ({ pageIndex, pageSize }) => {
    setpageNo(pageIndex);
    setLimit(pageSize);
  };

  const onUpdateColumnFilter: (data: string) => void = (data) => {
    /* istanbul ignore else */
    if (data !== searchPair.columnName) {
      setSearchPair({ columnName: data, columnInput: '' });
    }
  };

  const onUpdateColumnFilterValue: (data: string) => void = (data) => {
    const selectedInputIndex = viewFilterDataSanitized.column_value.indexOf(data);
    setSearchPair((prevState) => ({ ...prevState, columnInput: viewFilterData.column_value[selectedInputIndex] }));
  };

  const onUpdateFilterGroup: () => void = () => {
    setFilterGroup((prevState) => {
      const filterGroupList = prevState.filter((filter) => filter.columnName !== searchPair.columnName);
      return [...filterGroupList, { columnName: searchPair.columnName, columnInput: searchPair.columnInput }];
    });
  };

  const onDeleteFromFilterGroup: (data: searchPair[]) => void = (data) => {
    const deleteColumnNameList = data.map((column) => column.columnName);
    setFilterGroup((prevState) => (
      prevState.filter((filter) => !deleteColumnNameList.includes(filter.columnName))
    ));
  };

  const onFetchColumnFilterValues: (data: string) => void = (data) => {
    setSearchPair((prevState) => ({ ...prevState, columnInput: data }));
    dispatch(createRepoDeltaTableViewFilter(
      id,
      { filter_column: searchPair.columnName, filter_value: data },
    )());
  };

  return (
    <Col className="repository-content-cls">
      <Container className="emtl-delta-view-container-cls emtl-custom-container">
        <Row>
          <Col sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Inline spacing="2-x">
              <IconButton
                size="small"
                className="emtlDeltaViewContainer-button"
                onClick={
                  () => {
                    navigate('/repository/emtl');
                    dispatch(setFilterPanelOpen(true));
                  }
                }
                aria-label="close-repo-filter-drawer-view"
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="large">
                <b>EMTL Delta General Information</b>
              </Typography>
            </Inline>
          </Col>
          <Col sm={6} md={6} lg={6} xl={6} xxl={6}>
            <Inline align="end">
              <span><Crown /></span>
              <span className="emtlDeltaViewContainer-premium-button">Premium</span>
              <Toggle disabled />
              <Button size="small" className="emtlDeltaViewContainer-bookmark-button" disabled>
                <Bookmark />
                Bookmark
              </Button>
            </Inline>
          </Col>
        </Row>
        <Row>
          <MpdRepositoryEmtlDeltaGeneralInfoCard data={generalInfoCardData} id={id} />
        </Row>
        <Row style={{ margin: '32px 0px' }}>
          <Col>
            <div style={{ display: 'block' }}>
              <GenericTable
                tableTitle={TABLE_TITLE}
                tableCols={columns}
                tableData={viewData.records}
                totalDataCount={viewData.totalHits}
                onPaginationChangeCallback={onPaginationChange}
                Loading={viewDataLoading}
                generateTableColumns={generateTableColumns}
                tableColumnState={TableColumnState}
                getTableColumns={getTableColumns}
                generateHidableColumns={generateHidableColumns}
                searchPair={searchPair}
                columnFilter={searchOptions}
                filterGroup={filterGroup}
                noSearchKeySizeLimitColumns={viewData.filter_data}
                noSearchColumns={NO_SEARCH_COLUMNS}
                updateColumnFilter={onUpdateColumnFilter}
                updateColumnFilterValue={onUpdateColumnFilterValue}
                updateFilterGroup={onUpdateFilterGroup}
                deleteFromFilterGroup={onDeleteFromFilterGroup}
                fetchColumnFilterValues={onFetchColumnFilterValues}
                cssClass="emtl-delta-view-table"
                filterChipPositionDetached
                isDataDynamic
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Col>
  );
};

export default EmtlDeltaViewContainer;
